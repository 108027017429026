<template>
  <div>
    <el-form
      class="add-form"
      @submit.prevent="checkChargeInformation"
      @keydown.enter.prevent="() => {}"
      :model="charge"
      label-position="top"
      ref="chargeForm"
    >
      <div class="form-wrapper">
        <!-- Catégorie -->
        <el-form-item
          class="form-group-wrapper-item"
          :label="`${$t('CHARGES.CATEGORY')} (*)`"
          prop="category"
          :rules="[
            {
              required: true,
              message: $t('CHARGES.CATEGORY_REQUIRED'),
            },
          ]"
        >
          <el-select
            :name="`${$t('CHARGES.CATEGORY')}`"
            :placeholder="$t('CHARGES.CATEGORY')"
            @onchange="
              () => {
                onFormChanged();
              }
            "
            v-model="charge.category"
          >
            <el-option
              v-for="(charge, val) in chargeCategories"
              :key="val"
              :value="val"
              :label="`${val ? $t('CHARGES.' + val) : ''}`"
            />
          </el-select>
          <validation-error :errors="apiValidationErrors.category" />
        </el-form-item>
      </div>
      <div class="form-wrapper">
        <!-- Type -->
        <el-form-item
          class="form-group-wrapper-item"
          :label="`${$t('CHARGES.TYPE')} (*)`"
          prop="charge_type"
          :rules="[
            {
              required: true,
              message: $t('CHARGES.TYPE_REQUIRED'),
            },
          ]"
        >
          <el-select
            :name="`${$t('CHARGES.TYPE')}`"
            v-model="tempChargeType"
            :placeholder="$t('CHARGES.TYPE')"
            @input="onChargeTypeInput"
          >
            <el-option
              v-for="(charge, val) in chargeTypes"
              :key="val"
              :value="val"
              :label="`${val ? $t('CHARGES.' + val) : ''}`"
            />
          </el-select>
          <validation-error :errors="apiValidationErrors.charge_type" />
        </el-form-item>
      </div>
      <div class="form-wrapper">
        <!-- Name -->
        <el-form-item
          :class="{
            'form-group-wrapper-item': true,
            'w-100': charge.charge_type == typePalier,
          }"
          :label="`${$t('COMMON.NAME')} (*)`"
          prop="name"
          :rules="[
            {
              required: true,
              message: $t('CHARGES.NAME_REQUIRED'),
            },
          ]"
        >
          <el-input
            :placeholder="$t('COMMON.NAME')"
            v-model="charge.name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.name" />
        </el-form-item>
      </div>
      <div class="form-wrapper" v-if="charge.charge_type == typeMontantFixe">
        <!-- MONTANT -->
        <el-form-item
          class="form-group-wrapper-item"
          prop="amount"
          :label="`${$t('CHARGES.AMOUNT')} (*)`"
          :rules="[
            {
              required: true,
              message: $t('CHARGES.AMOUNT_REQUIRED'),
            },
          ]"
        >
          <el-input
            :placeholder="$t('CHARGES.AMOUNT')"
            type="number"
            v-model="charge.amount"
            :disabled="!isEmpty(charge.percentage)"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.amount" />
        </el-form-item>
      </div>
      <div class="form-wrapper" v-if="charge.charge_type == typePourcentage">
        <!-- POURCENTAGE -->
        <el-form-item
          class="form-group-wrapper-item"
          prop="percentage"
          :label="`${$t('CHARGES.PERCENTAGE')} (*)`"
          :rules="[
            {
              required: true,
              message: $t('CHARGES.PERCENTAGE_REQUIRED'),
            },
          ]"
        >
          <el-input
            :placeholder="$t('CHARGES.PERCENTAGE')"
            type="number"
            v-model="charge.percentage"
            :disabled="!isEmpty(charge.amount)"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.percentage" />
        </el-form-item>
      </div>
      <div
        class="form-wrapper paliers full"
        v-if="charge.charge_type == typePalier"
      >
        <!-- Section Paliers -->
        <h3>{{ $t("CHARGES.PALIERS") }} (*)</h3>
        <!-- TIERS ou PALIER -->
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush form-table"
            header-row-class-name="thead-light"
            :data="charge.tiers"
          >
            <el-table-column
              :label="$t('CHARGES.RANGE')"
              prop="range"
              min-width="150"
            >
              <template slot-scope="scope">
                <!-- Form Item for min_value and max_value -->
                <el-form-item
                  :prop="'tiers.' + scope.$index + '.range'"
                  :rules="[
                    {
                      validator: (rule, value, callback) =>
                        validateRange(scope.row, callback),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-row>
                    <!-- Label for min_value -->
                    <el-col :span="4">
                      <label :for="'min_value_' + scope.$index">{{
                        $t("CHARGES.MIN")
                      }}</label>
                    </el-col>
                    <!-- min_value input -->
                    <el-col :span="18">
                      <el-input
                        type="number"
                        :id="'min_value_' + scope.$index"
                        class="my-1"
                        v-model="scope.row.min_value"
                        :placeholder="$t('CHARGES.MIN_VALUE')"
                      />
                    </el-col>
                  </el-row>

                  <el-row>
                    <!-- Label for max_value -->
                    <el-col :span="4">
                      <label :for="'max_value_' + scope.$index">{{
                        $t("CHARGES.MAX")
                      }}</label>
                    </el-col>
                    <!-- max_value input -->
                    <el-col :span="18">
                      <el-input
                        type="number"
                        :id="'max_value_' + scope.$index"
                        class="my-1"
                        v-model="scope.row.max_value"
                        :placeholder="$t('CHARGES.MAX_VALUE')"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column :label="$t('CHARGES.COUT')" min-width="150">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tiers.' + scope.$index + '.cout'"
                  :rules="[
                    {
                      validator: (rule, value, callback) =>
                        validateCout(scope.row, callback),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    type="number"
                    class="my-1"
                    v-model="scope.row.amount"
                    :placeholder="$t('CHARGES.AMOUNT')"
                    :disabled="!isEmpty(scope.row.percentage)"
                  />
                  <el-input
                    type="number"
                    class="my-1"
                    v-model="scope.row.percentage"
                    :placeholder="$t('CHARGES.PERCENTAGE')"
                    :disabled="!isEmpty(scope.row.amount)"
                  />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.EXCERPT')" min-width="200">
              <template slot-scope="scope">
                <el-form-item :prop="'tiers.' + scope.$index + '.excerpt'">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :placeholder="$t('COMMON.EXCERPT')"
                    v-model="scope.row.excerpt"
                  />
                </el-form-item>
              </template>
            </el-table-column>

            <!-- Colonne pour supprimer un palier -->
            <el-table-column
              :label="$t('CHARGES.ACTIONS')"
              prop="actions"
              min-width="70"
            >
              <template slot-scope="scope">
                <el-form-item :prop="'tiers.' + scope.$index + '.button'">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="removePalier(scope.$index)"
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <base-button
          type="button"
          class="btn base-button elite-button add ml-auto btn-icon btn-fab btn-default btn-sm my-2"
          @click="addPalier"
        >
          <i class="far fa-plus" />
          {{ $t("CHARGES.ADD_PALIER") }}
        </base-button>
      </div>
      <div class="form-wrapper full">
        <!-- Section Commision -->
        <div
          v-if="charge.category == categoryFee"
          class="charge-selection width_1"
        >
          <h3>{{ $t("CHARGES.ADD_COMMISSION") }} (*)</h3>
          <charge-selector
            :charge="charge.commission?.id"
            :filterable="true"
            :showAll="false"
            :allowCreate="false"
            :selectedCategory="categoryCommission"
            @chargeChanged="commissionSelected"
          />
        </div>
      </div>
      <div class="submit-wrapper">
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          @click.prevent="checkChargeInformation"
          :disabled="loading || processing"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
          {{ charge.id ? $t("CHARGES.EDIT_CHARGE") : $t("CHARGES.ADD_CHARGE") }}
        </base-button>
      </div>
    </el-form>
    <modal
      :show="showChargeCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("CHARGES.SAME_CHARGES", {
                name: charge.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="charges"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('CHARGES.CATEGORY')"
                prop="category"
                min-width="100"
              >
                <template v-slot="{ row }">
                  <span class="text-uppercase">
                    {{ $t("CHARGES." + row?.category) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleChargeCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Option,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Button,
  Row,
  Col,
  Radio,
  MessageBox,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import ChargeSelector from "../components/ChargeSelector.vue";
import {
  TYPE_PALIER,
  TYPE_POURCENTAGE,
  TYPE_MONTANT_FIXE,
  CHARGE_CATEGORIES,
  CHARGE_TYPES,
  CATEGORY_COMMISSION,
  CATEGORY_FEE,
} from "@/constants/charges";

export default {
  layout: "DashboardLayout",

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Radio.name]: Radio,
    ChargeSelector,
  },

  mixins: [formMixin],

  props: ["chargeData", "formErrors", "loading", "hideSubmit"],

  data() {
    let chargeData = { ...this.chargeData };
    return {
      tiersType: TYPE_POURCENTAGE,
      charge: chargeData,
      chargeCategories: CHARGE_CATEGORIES,
      categoryCommission: CATEGORY_COMMISSION,
      categoryFee: CATEGORY_FEE,
      typePalier: TYPE_PALIER,
      typePourcentage: TYPE_POURCENTAGE,
      typeMontantFixe: TYPE_MONTANT_FIXE,
      chargeTypes: CHARGE_TYPES,
      charges: [],
      paliers: [],
      total: 0,
      showModal: false,
      showChargeCheckModal: false,
      processing: false,
      tempChargeType: chargeData.charge_type, // Valeur temporaire
      lastValidChargeType: TYPE_POURCENTAGE, // Dernière valeur confirmée
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    commissionSelected(commision) {
      this.charge.commission = {
        type: "charges",
        id: commision,
      };
    },
    isEmpty(string) {
      return string === null || string === "";
    },
    validateRange(row, callback) {
      if (row.min_value !== null && row.max_value !== null) {
        // Si min_value est supérieur ou égal à max_value, retourner une erreur
        if (parseInt(row.min_value) >= parseInt(row.max_value)) {
          callback(new Error(this.$t("CHARGES.MIN_LESS_THAN_MAX")));
        } else {
          callback();
        }
      }
      // Remplir au moins un
      else if (row.min_value || row.max_value) {
        callback();
      } else {
        callback(new Error(this.$t("CHARGES.RANGE_REQUIRED")));
      }
    },
    validateCout(row, callback) {
      if (row.amount || row.percentage) {
        callback();
      } else {
        callback(new Error(this.$t("CHARGES.COUT_REQUIRED")));
      }
    },
    // Méthode pour ajouter un palier
    addPalier() {
      this.charge.tiers.push({
        min_value: null,
        max_value: null,
        amount: null,
        percentage: null,
        excerpt: "",
      });
    },
    // Méthode pour supprimer un palier
    removePalier(index) {
      this.charge.tiers.splice(index, 1);
    },

    async handleSubmit() {
      const checkUniqueCharge = await this.checkChargeUniqueInformation();
      if (checkUniqueCharge) {
        await this.sendInformation();
      } else {
        this.showChargeCheckModal = true;
      }
    },

    async sendInformation() {
      if (this.charge.name) {
        this.charge.name = this.charge.name.toString();
      }
      let chargeData = cloneDeep(this.charge);
      this.$emit("chargeSubmitted", chargeData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    hasTiers() {
      return this.charge.tiers && this.charge.tiers.length;
    },

    async onChargeTypeInput(newType) {
      if (
        this.hasTiers() ||
        !this.isEmpty(this.charge.amount) ||
        !this.isEmpty(this.charge.percentage)
      ) {
        try {
          // Afficher une confirmation avant de supprimer les paliers
          await MessageBox.confirm(
            this.$t("CHARGES.CLEAR_FORM"),
            this.$t("CHARGES.CONFIRM_CLEAR_FORM_TITLE"),
            {
              confirmButtonText: this.$t("CHARGES.CLEAR_FORM_CONFIRM_BUTTON"),
              cancelButtonText: this.$t("CHARGES.CANCEL"),
              type: "warning",
            }
          );
          // Si confirmé, met à jour le modèle charge avec la nouvelle valeur
          this.charge.charge_type = newType;
          this.charge.amount = null;
          this.charge.percentage = null;
          this.charge.tiers = [];
          this.lastValidChargeType = newType; // Mettre à jour la dernière valeur confirmée
          if (newType == this.typePalier) {
            this.addPalier();
          }
        } catch (error) {
          // Si l'utilisateur annule, réinitialise tempChargeType à la dernière valeur confirmée
          this.tempChargeType = this.lastValidChargeType;
        }
      } else {
        // Met à jour directement si pas de tiers ou valeurs présentes
        this.charge.charge_type = newType;
        this.lastValidChargeType = newType;
        if (newType == this.typePalier) {
          this.addPalier();
        }
      }
      this.onFormChanged();
    },

    async getList() {
      if (!this.charge?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.charge?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };
        await this.$store.dispatch("charges/list", params);
        this.charges = this.$store.getters["charges/list"];
        this.total = this.$store.getters["charges/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkChargeUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.charge?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleChargeCheckModalState();
      await this.sendInformation();
    },

    async toggleChargeCheckModalState() {
      this.showChargeCheckModal = !this.showChargeCheckModal;
    },

    checkChargeInformation() {
      this.$refs["chargeForm"].validate(async (valid) => {
        if (valid) {
          if (this.charge.name && this.charge.category) {
            this.handleSubmit();
            this.showModal = false;
          } else {
            this.showModal = true;
          }
        }
      });
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    chargeData(chargeData) {
      if (chargeData) {
        this.charge = {
          ...this.charge,
          ...cloneDeep(chargeData),
        };
      }
    },
  },
};
</script>
<style>
.add-form .el-input__inner,
.add-form .el-textarea {
  background-color: transparent;
  border-radius: 8px;
  color: #000;
  border-color: #d1d5db !important;
}
.form-group-wrapper-item .el-select {
  width: 100%;
}
.form-group-wrapper-item label {
  margin-bottom: 0.1rem !important;
}
.form-group-wrapper-item .el-form-item__label {
  padding: 0 0 1px !important;
}
</style>
