<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ charge.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_CHARGES)"
            @click="deleteCharge"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_CHARGES)"
            @click="editCharge"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("CHARGES.CATEGORY") }}</dt>
          <dd class="text-uppercase" v-if="charge?.category">
            {{ $t("CHARGES." + charge?.category) }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.name">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ charge.name ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.amount">
          <dt>{{ $t("CHARGES.AMOUNT") }}</dt>
          <dd>
            {{ "$" + charge.amount ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.percentage">
          <dt>{{ $t("CHARGES.PERCENTAGE") }}</dt>
          <dd>
            {{ "%" + charge.percentage ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $formatDate(charge.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $formatDate(charge.updated_at) }}
          </dd>
        </dl>
      </div>

      <div class="paliers my-1" v-if="charge.tiers && charge.tiers.length">
        <tiers-list
          :tiers="charge.tiers"
          :title="$t('CHARGES.PALIERS')"
          currency="$"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TiersList from "../components/TiersList.vue";
export default {
  name: "charge-view-global",
  components: {
    TiersList,
  },
  props: ["charge"],

  data() {
    return {};
  },

  created() {},

  methods: {
    chargeUpdated() {
      this.$emit("chargeUpdated", true);
    },
    editCharge() {
      this.$emit("onEditCharge", this.charge);
    },
    deleteCharge() {
      this.$emit("onDeleteCharge", this.charge);
    },
  },

  mounted() {},

  watch: {
    charge(charge) {},
  },
};
</script>
