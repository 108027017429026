import { TYPE_POURCENTAGE, CATEGORY_FEE } from "@/constants/charges";
export default {
  type: "charges",
  name: null,
  charge_type: TYPE_POURCENTAGE,
  amount: null,
  percentage: null,
  tiers: [],
  category: CATEGORY_FEE,
  relationshipNames: ["commission"],
  commission: null,
};
